import React from "react"
import { Link, graphql } from "gatsby"
import _ from "lodash"
import { withPreview } from "gatsby-source-prismic"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query TopPageQuery {
    allPrismicOnlineLesson {
      edges {
        node {
          data {
            order
            title {
              text
            }
          }
          uid
        }
      }
    }
    allPrismicOtherSupport {
      edges {
        node {
          data {
            order
            title {
              text
            }
          }
          uid
        }
      }
    }
    prismicTopPage {
      data {
        profile_img {
          fluid(maxWidth: 100) {
            ...GatsbyPrismicImageFluid
          }
        }
        intro_side_big_img {
          fluid(maxWidth: 800) {
            ...GatsbyPrismicImageFluid
          }
        }
        intro_text_body {
          text
        }
        intro_text_heading {
          text
        }
        online_lesson_heading {
          text
        }
        online_lesson_img {
          fluid(maxWidth: 700) {
            ...GatsbyPrismicImageFluid
          }
        }
        online_lesson_text {
          text
        }
        other_service_heading {
          text
        }
        other_service_img {
          fluid(maxWidth: 700) {
            ...GatsbyPrismicImageFluid
          }
        }
        other_service_text {
          text
        }
        testimonials_text {
          text
        }
        testimonials_heading {
          text
        }
        testimonials_comments {
          image {
            fixed(width: 100) {
              ...GatsbyPrismicImageFixed
            }
          }
          text {
            text
          }
        }
      }
    }
  }
`

const pickDataForView = originalData => {
  const d = originalData.prismicTopPage.data

  const profile = {
    img: {
      fluid: d.profile_img.fluid,
    },
  }
  const intro = {
    sideBigImg: {
      fluid: d.intro_side_big_img.fluid,
    },
    body: {
      heading: d.intro_text_heading.text,
      text: d.intro_text_body.text,
    },
  }
  const onlineLesson = {
    heading: d.online_lesson_heading.text.split("<br>"),
    text: d.online_lesson_text.text,
    img: {
      fluid: d.online_lesson_img.fluid,
    },
  }
  const otherService = {
    heading: d.other_service_heading.text,
    text: d.other_service_text.text,
    img: {
      fluid: d.other_service_img.fluid,
    },
  }
  const testimonials = {
    heading: d.testimonials_heading.text,
    text: d.testimonials_text.text,
    items: d.testimonials_comments.map(item => {
      return {
        img: {
          fixed: item.image.fixed,
        },
        text: item.text.text,
      }
    }),
  }

  let lessonEntries = originalData.allPrismicOnlineLesson.edges.map(entry => {
    return {
      uid: entry.node.uid,
      title: entry.node.data.title.text,
      order: entry.node.data.order,
    }
  })
  lessonEntries = _.sortBy(lessonEntries, entry => entry.order)

  let otherSupportEntries = originalData.allPrismicOtherSupport.edges.map(
    entry => {
      return {
        uid: entry.node.uid,
        title: entry.node.data.title.text,
        order: entry.node.data.order,
      }
    }
  )
  otherSupportEntries = _.sortBy(otherSupportEntries, entry => entry.order)

  const createNav1Data = () => {
    const result = []
    lessonEntries.forEach(entry => {
      result.push({
        path: `/${entry.uid}/`,
        title: entry.title,
      })
    })
    otherSupportEntries.forEach(entry => {
      if (entry.uid !== "art-academy-germany") {
        return
      }
      result.push({
        path: `/other-support/${entry.uid}/`,
        title: entry.title,
      })
    })
    return result
  }

  const createNav2Data = () => {
    const result = []
    otherSupportEntries.forEach(entry => {
      if (entry.uid === "art-academy-germany") {
        return
      }
      result.push({
        path: `/other-support/${entry.uid}/`,
        title: entry.title,
      })
    })
    return result
  }
  const nav1Data = createNav1Data()
  const nav2Data = createNav2Data()

  return {
    profile,
    intro,
    onlineLesson,
    otherService,
    testimonials,
    nav1Data,
    nav2Data,
  }
}

const Page = ({ data }) => {
  data = pickDataForView(data)

  return (
    <Layout breadPaths={[]}>
      <SEO title="Home" />

      <div className="h-2gap"></div>

      {/* 1st block */}

      <section className="flex flex-col-reverse lg:flex-row w-full relative">
        <div className="flex flex-col md:flex-row lg:flex-col lg:w-3col2gap lg:flex-grow-0">
          <div className="hidden lg:flex md:w-3col2gap flex-shrink-0 flex-row items-center">
            <div className="hidden xl:block relative mb-1gap md:mb-2gap mx-auto w-2/3 max-w-3col xl:w-full">
              <Img
                className="block rounded-full border-black border-1gap"
                fluid={data.profile.img.fluid}
                alt=""
              />
              <span
                className="hidden lg:block absolute w-screen border-black lg:border-t-1gap right-0 top-0"
                style={{ marginRight: "50%" }}
              ></span>
              <span
                className="hidden lg:block absolute w-full border-black lg:border-t-1gap left-0 bottom-0"
                style={{ marginLeft: "50%" }}
              ></span>
            </div>
          </div>

          <div className="flex flex-col flex-grow mb-2gap justify-center xl:justify-start">
            <div>
              <h2 className="font-bold text-2xl leading-tight mb-1gap">
                {data.intro.body.heading}
              </h2>
              <p>{data.intro.body.text}</p>
            </div>
          </div>
        </div>

        <div className="flex-grow lg:pl-1gap xl:pl-1col overflow-hidden relative">
          <div className="lg:pl-1gap pg:pt-1gap pb-1gap lg:pb-1col relative">
            <div className="bg-black border-black border-1/2gap lg:border-1gap lg:-ml-1gap">
              <Img
                className="block w-full shadow-md max-w-5col4gap lg:max-w-full mx-auto"
                fluid={data.intro.sideBigImg.fluid}
                alt=""
              />
            </div>
            <span className="hidden lg:block absolute w-screen border-black border-t-1gap left-0 top-0"></span>
            <span className="hidden lg:block absolute h-screen border-black border-l-1gap left-0 top-0"></span>
          </div>
        </div>

        <span
          className="hidden lg:block absolute w-screen border-black border-t-1gap top-0"
          style={{ left: "50%" }}
        ></span>
      </section>

      {/* 2nd block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex lg:flex-row-reverse over:-mx-1col1gap over:w-14col13gap">
        <div className="flex-grow-0 lg:w-1/2 xl:w-auto">
          <div className="w-full md:w-4col3gap lg:w-full xl:w-6col5gap over:w-7col6gap">
            <div className="pb-2gap lg:pb-1col md:mr-1gap lg:mr-0 lg:ml-1col xl:ml-1col1gap over:pr-1col1gap">
              <div className="relative hidden lg:block pt-3gap">
                <span className="absolute left-0 top-0 border-black border-t-1gap w-screen">
                  <span className="hidden lg:block absolute left-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -ml-2gap"></span>
                </span>
              </div>

              <h2 className="font-bold text-3xl leading-tight mb-1gap">
                <Link to="/online-lesson/">
                  {data.onlineLesson.heading}
                  <br />
                  {data.onlineLesson.heading[1]}
                </Link>
              </h2>
              <p className="mb-1gap">{data.onlineLesson.text}</p>
              <ul className="space-y-2 mt-2 ml-1gap leading-tight">
                {data.nav1Data.map((entry, i) => {
                  return (
                    <li className="list-item-triangle-black" key={i}>
                      <Link to={entry.path}>{entry.title}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden md:block lg:w-1/2 xl:w-auto flex-grow">
          <div className="pb-1col">
            <Img
              className="block w-full border-black border-1/2gap lg:border-1gap shadow-md"
              fluid={data.onlineLesson.img.fluid}
              alt=""
            />
          </div>
        </div>
      </section>

      {/* 3rd block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex md:flex-row-reverse lg:flex-row relative over:-mx-1col1gap over:w-14col13gap">
        <div className="flex-grow-0 lg:w-1/2 xl:w-auto">
          <div className="w-full md:w-4col3gap lg:w-full xl:w-6col5gap over:w-7col6gap">
            <div className="pb-2gap lg:pb-1col md:ml-1gap lg:ml-0 lg:mr-1col xl:mr-1col1gap over:pl-1col1gap">
              <div className="relative hidden lg:block pt-3gap">
                <span className="absolute right-0 top-0 border-black border-t-1gap w-screen">
                  <span className="hidden lg:block absolute right-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -mr-2gap"></span>
                </span>
              </div>

              <h2 className="font-bold text-3xl leading-tight mb-1gap">
                {data.otherService.heading}
              </h2>
              <p className="mb-1gap">{data.otherService.text}</p>
              <ul className="space-y-2 mt-2 ml-1gap leading-tight">
                {data.nav2Data.map((entry, i) => {
                  return (
                    <li className="list-item-triangle-black" key={i}>
                      <Link to={entry.path}>{entry.title}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden md:block lg:w-1/2 xl:w-auto flex-grow">
          <Img
            className="block w-full border-black border-1/2gap lg:border-1gap lg:-mb-1gap"
            fluid={data.otherService.img.fluid}
            alt=""
          />
        </div>
      </section>

      {/* 4th block */}

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section>
        <div className="relative hidden lg:block pt-3gap">
          <span className="absolute left-0 top-0 border-black border-t-1gap w-screen">
            <span className="hidden lg:block absolute left-0 top-0 border-black border-3/2gap rounded-full -mt-2gap -ml-1/2gap xl:-ml-2gap"></span>
          </span>
        </div>

        <h2 className="font-bold text-3xl leading-tight mb-1gap">
          {data.testimonials.heading}
        </h2>
        <p className="mb-2gap">{data.testimonials.text}</p>
        <ul className="grid lg:grid-cols-2 gap-1gap mb-1col">
          {data.testimonials.items.map((item, i) => (
            <li className="flex items-center" key={i}>
              <div className="flex-shrink-0 w-1col1gap h-1col1gap">
                <Img
                  className="block rounded-full shadow-md w-full h-full"
                  fixed={item.img.fixed}
                  alt=""
                />
              </div>
              <p className="flex-grow ml-1gap">{item.text}</p>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  )
}

export default withPreview(Page)
